<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card outlined raised>
          <v-card-text>
            <v-form v-on:submit.prevent="generateReport">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                      dense outlined
                      hide-details
                      clearable
                      :items="reportTypeFilter"
                      v-model="searchFormData.type"
                      item-text="title"
                      item-value="value"
                      label="Report Type*"
                      @change="rtChange($event)"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4"
                       v-if="searchFormData.type && searchFormData.type !=='7' && searchFormData.type !=='8'">
                  <v-autocomplete
                      v-model="searchFormData.country_id"
                      :items="countriesFilter"
                      item-text="title"
                      item-value="id"
                      outlined
                      clearable
                      hide-details
                      dense
                      chips
                      small-chips
                      label="Country*"
                      @change="countrySelected"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4"
                       v-if="searchFormData.type && searchFormData.country_id && searchFormData.type !=='7' && searchFormData.type !=='8'">
                  <v-autocomplete
                      v-model="searchFormData.city_id"
                      :items="citiesFilter"
                      item-text="title"
                      item-value="id"
                      outlined
                      clearable
                      dense
                      hide-details
                      chips
                      small-chips
                      label="City*"
                      @change="cityChange"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12"
                       v-if="
                       (searchFormData.type && searchFormData.type !== '1' && searchFormData.type !== '2' && searchFormData.country_id && searchFormData.city_id)
                       || (searchFormData.type && searchFormData.type === '7')
                       || (searchFormData.type && searchFormData.type === '8')
                          ">
                  <v-autocomplete
                      v-model="searchFormData.development_id"
                      :items="developmentsFilter"
                      item-text="text"
                      item-value="id"
                      no-filter
                      clearable
                      outlined
                      dense
                      return-object
                      deletable-chips
                      chips
                      :loading="isDevelopmentsLoaded"
                      small-chips
                      hide-details
                      placeholder="Search Developments and Projects"
                      label="Location (min. 3 characters required)"
                      @keyup="developmentsFilterKeyUp"
                      @change="developmentChange($event)"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row
                  v-if="
                  (searchFormData.type && searchFormData.country_id && searchFormData.city_id)
                   || (searchFormData.type && searchFormData.type === '7')
                   || (searchFormData.type && searchFormData.type === '8')
                    ">
                <v-col cols="12" sm="6">
                  <button :disabled="loading" @click="generateReport" type="submit"
                          style="width: 100%"
                          class="btn btn-success theme-btn ml-auto mr-2">
                    Generate Report
                    <v-icon class="ml-2" small>fas fa-tasks</v-icon>
                  </button>
                </v-col>
                <v-col cols="12" sm="6">
                  <button :disabled="loading" style="width: 100%" @click="reset"
                          class="btn btn-primary ml-auto">
                    Reset
                    <v-icon class="ml-2" small>fas fa-times</v-icon>
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="items.length > 0">
      <v-col cols="12" sm="12">
        <v-card outlined raised>
          <v-card-title>
            <span v-if="searchFormData.type !== '8'">{{ addTSBVZ(totalRecords) }} Record(s) Found</span>
            <span v-if="searchFormData.type === '8'">
              {{ totalEmailCount }} Emails Addresses <br>
              {{ totalPhoneCount }} Phone Numbers
            </span>
            <v-spacer></v-spacer>
            <v-btn @click="reportUpdateAll" color="#00004d" dark
                   class="ml-auto ma-3"
                   v-if="searchFormData.type === '8'">
              Update All
              <v-icon dark x-small class="ml-1">fas fa-save</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="pagination"
              :server-items-length="totalRecords"
              :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
              :loading="loading"
              v-show="!isLoaded"
              mobile-breakpoint="100"
              class="elevation-0 custom-td-table">
            <template v-slot:item.text="{ item }">
              <div class="text-truncate">
                <p v-html="item.text"></p>
              </div>
            </template>
            <template v-slot:item.emails="{ item }">
              <div class="text-truncate" v-if="item.contact_emails.length > 0">
                <v-text-field
                    v-for="(email, i) in item.contact_emails"
                    :key="i"
                    v-model="email.title"
                    :label="'Email ' + (i+1)"
                    flat
                    :counter="100"
                    maxLength="100"
                    hide-details
                    clearable
                ></v-text-field>
              </div>
            </template>
            <template v-slot:item.phones="{ item }">
              <div class="text-truncate" v-if="item.contact_phones.length > 0">
                <v-text-field
                    v-for="(phone, i) in item.contact_phones"
                    :key="i"
                    v-model="phone.title"
                    :label="'Phone ' + (i+1)"
                    flat
                    :counter="100"
                    maxLength="100"
                    hide-details
                    clearable
                ></v-text-field>
              </div>
            </template>
            <template v-slot:item.full_name="{ item }">
              <div class="text-truncate">
                <p v-html="item.full_name + '<br>' + 'No. of Properties: ' + item.no_of_properties + '<br>' + 'Nationality: ' + item.nationalities"></p>
              </div>
            </template>
            <template v-slot:item.type_of_contact="{ item }">
              <div class="text-truncate pt-2 pb-2">
                <v-radio-group v-model="item.type_of_contact" row>
                  <v-radio
                      label="Individual"
                      value="0"
                  ></v-radio>
                  <v-radio
                      label="Company"
                      value="1"
                  ></v-radio>
                </v-radio-group>
              </div>
            </template>
            <template v-slot:item.count="{ item }">
              <div class="text-truncate">
                <p v-if="item.hasOwnProperty('development_unit_details_count')"
                   v-html="item.development_unit_details_count"></p>
                <p v-else-if="item.hasOwnProperty('project_unit_details_count')"
                   v-html="item.project_unit_details_count"></p>
                <p v-else-if="item.hasOwnProperty('nat_contact_details_count')"
                   v-html="item.nat_contact_details_count"></p>
                <p v-else>0</p>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="searchFormData.type === '5'">
      <div class="col-lg-4 col-sm-4">
        <div class="bg-light-success text-center pt-3 pb-3">
          <p class="font-weight-bold font-size-h3 mb-3">
            Total Units
          </p>
          <p class="font-weight-bold font-size-h6 m-0">
            {{ totalUnits }}
          </p>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import {GET_REPORT, GET_REPORT_TABLE, REPORT_TRANSACTIONS_TITLES} from "@/core/services/store/reports.module";
import {SET_SNACK} from "@/core/services/store/snackbar.module";
import {COUNTRIES_SIMPLE_LIST} from "@/core/services/store/countries.module";
import {CITYS_SIMPLE_LIST} from "@/core/services/store/cities.module";
import {
  DEVELOPMENTS_AND_PROJECTS_SIMPLE_LIST,
} from "@/core/services/store/developments.module";
import {
  UPDATE_CONTACT_DETAILS
} from "@/core/services/store/contacts.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";

export default {
  name: "reports",
  watch: {
    params: {
      handler() {
        if (this.searchFormData.type === '1' || this.searchFormData.type === '2' || this.searchFormData.type === '3' || this.searchFormData.type === '4' || this.searchFormData.type === '7' || this.searchFormData.type === '8') {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getCountries();
  },
  data() {
    return {
      headers: [],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      items: [],
      searchFormData: {},
      countriesFilter: [],
      citiesFilter: [],
      developmentsFilter: [],
      isDevelopmentsLoaded: false,
      projectsFilter: [],
      isProjectsLoaded: false,
      totalUnits: 0,
      totalEmailCount: 0,
      totalPhoneCount: 0,
      typeOfContact: [
        {value: "0", title: 'Individual'},
        {value: "1", title: 'Institution/Company/Organization'}
      ],
    }
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    getHeaders() {
      if (this.searchFormData.type && this.searchFormData.type === '8') {
        this.headers = [
          {text: 'Name', value: 'full_name', name: 'full_name', filterable: false, sortable: false},
          {text: 'Type of Contact', value: 'type_of_contact', name: 'type_of_contact', filterable: false, sortable: false},
          {text: 'Email', width: "300px", value: 'emails', name: 'emails'},
          {text: 'Phone', width: "300px", value: 'phones', name: 'phones'},
        ]
      } else {
        this.headers = [
          {text: 'Title', value: 'text', name: 'text', filterable: true, sortable: false},
          {text: 'Count', value: 'count', name: 'count'},
        ]
      }
      for (var i = 0; i < this.headers.length; i++) {
        this.columns.push(
            '&columns[' + i + '][data]=' + this.headers[i].value +
            '&columns[' + i + '][name]=' + this.headers[i].name +
            '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
            '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
            '&columns[' + i + '][search][value]=' +
            '&columns[' + i + '][search][regex]=' + false
        );
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query += '&' + searchFormDataQuery
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 0 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(GET_REPORT_TABLE, {q: query, columns: this.columns}).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          if (this.searchFormData.type === '8') {
            this.totalEmailCount = res.records.total_email_count;
            this.totalPhoneCount = res.records.total_phone_count;
          }
          resolve({
            items,
            total
          });
        });
      });
    },
    getCountries() {
      this.$store.dispatch(COUNTRIES_SIMPLE_LIST).then((data) => {
        this.countriesFilter = data.records.countriesList;
      });
    },
    countrySelected(v) {
      if (this.searchFormData.hasOwnProperty('city_id') && this.searchFormData.city_id) {
        this.searchFormData.city_id = ''
      }
      if (v) {
        this.$store.dispatch(CITYS_SIMPLE_LIST, {country_id: v}).then((data) => {
          this.citiesFilter = data.records.citiesList;
        });
      } else {
        this.searchFormData.city_id = 'null'
      }
    },
    developmentsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isDevelopmentsLoaded = true;
          that.$store
              .dispatch(DEVELOPMENTS_AND_PROJECTS_SIMPLE_LIST, {
                type: that.searchFormData.type === '3' || that.searchFormData.type === '4' ? 'development' : 'all',
                q: e.target.value,
                countryId: that.searchFormData.country_id,
                cityId: that.searchFormData.city_id
              })
              .then(data => {
                that.developmentsFilter = data.records.results;
                that.isDevelopmentsLoaded = false;
              });
        }
      }, 900);
    },
    drChange(v) {
      if (v) {
        if (v === "1") {
          let date = new Date();
          let cdate = new Date();
          let lastmonths = new Date(cdate.setMonth(cdate.getMonth() - 1));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = lastmonths.getFullYear() + '-' + ('0' + (lastmonths.getMonth() + 1)).slice(-2) + '-' + ('0' + lastmonths.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "2") {
          let date = new Date();
          let cdate = new Date();
          let last3months = new Date(cdate.setMonth(cdate.getMonth() - 2));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last3months.getFullYear() + '-' + ('0' + (last3months.getMonth())).slice(-2) + '-' + ('0' + last3months.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "3") {
          let date = new Date();
          let cdate = new Date();
          let last6months = new Date(cdate.setMonth(cdate.getMonth() - 5));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last6months.getFullYear() + '-' + ('0' + (last6months.getMonth())).slice(-2) + '-' + ('0' + last6months.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "4") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = (date.getFullYear() - 1) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "5") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = date.getFullYear() + '-01-01';
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        }
      } else {
        this.searchFormData.toDateSearch = ''
        this.searchFormData.fromDateSearch = ''
      }
    },
    generateReport() {
      if (this.searchFormData.type === '1' || this.searchFormData.type === '2' || this.searchFormData.type === '3' || this.searchFormData.type === '4' || this.searchFormData.type === '7' || this.searchFormData.type === '8') {
        this.items = []
        this.pagination.page = 1
        this.totalRecords = 0
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      } else {
        this.loading = true
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.$store.dispatch(GET_REPORT, {q: searchFormDataQuery})
            .then((res) => {
              if (this.searchFormData.type === '5') {
                this.totalUnits = res.records.total_units
              }
            })
            .finally(() => {
              this.loading = false
            })
      }
    },
    reset() {
      this.searchFormData = {}
      this.items = []
      this.pagination.page = 1
      this.totalRecords = 0
      this.totalUnits = 0
      this.totalEmailCount = 0
      this.totalPhoneCount = 0
    },
    rtChange(e) {
      this.getHeaders();
      if (e) {
        this.items = []
        this.pagination.page = 1
        this.totalRecords = 0
      } else {
        this.searchFormData = {}
        this.items = []
        this.pagination.page = 1
        this.totalRecords = 0
      }
    },
    developmentChange(e) {
      if (e) {
        this.searchFormData.development_id = e.id
        this.searchFormData.location_ref = e.location_ref
      } else {
        this.searchFormData.development_id = ''
        this.searchFormData.location_ref = ''
      }
    },
    cityChange() {
      this.developmentsFilter = []
      this.searchFormData.development_id = ''
      this.searchFormData.location_ref = ''
    },
    copyLink() {
      let linkToCopy = document.querySelector('#report-link')
      linkToCopy.setAttribute('type', 'text')
      linkToCopy.select()
      try {
        let successful = document.execCommand('copy');
        if (successful) {
          this.$store.dispatch(SET_SNACK, {clr: 'success', msg: 'Report Link Copied Successfully'});
        } else {
          this.$store.dispatch(SET_SNACK, {clr: 'error', msg: 'Report Link Not Copied'});
        }
      } catch (err) {
        this.$store.dispatch(SET_SNACK, {clr: 'error', msg: 'Oops, Unable To Copy'});
      }
      linkToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    emailLink() {
      this.$store.state.reports.sharedReportLink = this.reportLink;
      this.$router.push({
        name: 'contacts',
        query: {share: 'report'}
      })
    },
    reportUpdateAll() {
      let that = this;
      let data  = {
        type: this.searchFormData.type === '8' ? 'contact_emails' : 'contact_phones',
        items: this.items
      }
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to update all", clr: 'primary', callback: function () {
          that.$store.dispatch(UPDATE_CONTACT_DETAILS, {data: data}).then(() => {
            that.items = []
            that.pagination.page = 1
            that.totalRecords = 0
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          })
        }
      });
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    }
  }
};
</script>
