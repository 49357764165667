var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"outlined":"","raised":""}},[_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.generateReport.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","clearable":"","items":_vm.reportTypeFilter,"item-text":"title","item-value":"value","label":"Report Type*"},on:{"change":function($event){return _vm.rtChange($event)}},model:{value:(_vm.searchFormData.type),callback:function ($$v) {_vm.$set(_vm.searchFormData, "type", $$v)},expression:"searchFormData.type"}})],1),(_vm.searchFormData.type && _vm.searchFormData.type !=='7' && _vm.searchFormData.type !=='8')?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.countriesFilter,"item-text":"title","item-value":"id","outlined":"","clearable":"","hide-details":"","dense":"","chips":"","small-chips":"","label":"Country*"},on:{"change":_vm.countrySelected},model:{value:(_vm.searchFormData.country_id),callback:function ($$v) {_vm.$set(_vm.searchFormData, "country_id", $$v)},expression:"searchFormData.country_id"}})],1):_vm._e(),(_vm.searchFormData.type && _vm.searchFormData.country_id && _vm.searchFormData.type !=='7' && _vm.searchFormData.type !=='8')?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.citiesFilter,"item-text":"title","item-value":"id","outlined":"","clearable":"","dense":"","hide-details":"","chips":"","small-chips":"","label":"City*"},on:{"change":_vm.cityChange},model:{value:(_vm.searchFormData.city_id),callback:function ($$v) {_vm.$set(_vm.searchFormData, "city_id", $$v)},expression:"searchFormData.city_id"}})],1):_vm._e(),(
                     (_vm.searchFormData.type && _vm.searchFormData.type !== '1' && _vm.searchFormData.type !== '2' && _vm.searchFormData.country_id && _vm.searchFormData.city_id)
                     || (_vm.searchFormData.type && _vm.searchFormData.type === '7')
                     || (_vm.searchFormData.type && _vm.searchFormData.type === '8')
                        )?_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.developmentsFilter,"item-text":"text","item-value":"id","no-filter":"","clearable":"","outlined":"","dense":"","return-object":"","deletable-chips":"","chips":"","loading":_vm.isDevelopmentsLoaded,"small-chips":"","hide-details":"","placeholder":"Search Developments and Projects","label":"Location (min. 3 characters required)"},on:{"keyup":_vm.developmentsFilterKeyUp,"change":function($event){return _vm.developmentChange($event)}},model:{value:(_vm.searchFormData.development_id),callback:function ($$v) {_vm.$set(_vm.searchFormData, "development_id", $$v)},expression:"searchFormData.development_id"}})],1):_vm._e()],1),(
                (_vm.searchFormData.type && _vm.searchFormData.country_id && _vm.searchFormData.city_id)
                 || (_vm.searchFormData.type && _vm.searchFormData.type === '7')
                 || (_vm.searchFormData.type && _vm.searchFormData.type === '8')
                  )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('button',{staticClass:"btn btn-success theme-btn ml-auto mr-2",staticStyle:{"width":"100%"},attrs:{"disabled":_vm.loading,"type":"submit"},on:{"click":_vm.generateReport}},[_vm._v(" Generate Report "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("fas fa-tasks")])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('button',{staticClass:"btn btn-primary ml-auto",staticStyle:{"width":"100%"},attrs:{"disabled":_vm.loading},on:{"click":_vm.reset}},[_vm._v(" Reset "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("fas fa-times")])],1)])],1):_vm._e()],1)],1)],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length > 0),expression:"items.length > 0"}]},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{attrs:{"outlined":"","raised":""}},[_c('v-card-title',[(_vm.searchFormData.type !== '8')?_c('span',[_vm._v(_vm._s(_vm.addTSBVZ(_vm.totalRecords))+" Record(s) Found")]):_vm._e(),(_vm.searchFormData.type === '8')?_c('span',[_vm._v(" "+_vm._s(_vm.totalEmailCount)+" Emails Addresses "),_c('br'),_vm._v(" "+_vm._s(_vm.totalPhoneCount)+" Phone Numbers ")]):_vm._e(),_c('v-spacer'),(_vm.searchFormData.type === '8')?_c('v-btn',{staticClass:"ml-auto ma-3",attrs:{"color":"#00004d","dark":""},on:{"click":_vm.reportUpdateAll}},[_vm._v(" Update All "),_c('v-icon',{staticClass:"ml-1",attrs:{"dark":"","x-small":""}},[_vm._v("fas fa-save")])],1):_vm._e()],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoaded),expression:"!isLoaded"}],staticClass:"elevation-0 custom-td-table",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.totalRecords,"footer-props":{
                  itemsPerPageOptions: _vm.rowsPerPageItems,
                  showFirstLastPage: true,
                },"loading":_vm.loading,"mobile-breakpoint":"100"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.text",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s(item.text)}})])]}},{key:"item.emails",fn:function(ref){
                var item = ref.item;
return [(item.contact_emails.length > 0)?_c('div',{staticClass:"text-truncate"},_vm._l((item.contact_emails),function(email,i){return _c('v-text-field',{key:i,attrs:{"label":'Email ' + (i+1),"flat":"","counter":100,"maxLength":"100","hide-details":"","clearable":""},model:{value:(email.title),callback:function ($$v) {_vm.$set(email, "title", $$v)},expression:"email.title"}})}),1):_vm._e()]}},{key:"item.phones",fn:function(ref){
                var item = ref.item;
return [(item.contact_phones.length > 0)?_c('div',{staticClass:"text-truncate"},_vm._l((item.contact_phones),function(phone,i){return _c('v-text-field',{key:i,attrs:{"label":'Phone ' + (i+1),"flat":"","counter":100,"maxLength":"100","hide-details":"","clearable":""},model:{value:(phone.title),callback:function ($$v) {_vm.$set(phone, "title", $$v)},expression:"phone.title"}})}),1):_vm._e()]}},{key:"item.full_name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s(item.full_name + '<br>' + 'No. of Properties: ' + item.no_of_properties + '<br>' + 'Nationality: ' + item.nationalities)}})])]}},{key:"item.type_of_contact",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-truncate pt-2 pb-2"},[_c('v-radio-group',{attrs:{"row":""},model:{value:(item.type_of_contact),callback:function ($$v) {_vm.$set(item, "type_of_contact", $$v)},expression:"item.type_of_contact"}},[_c('v-radio',{attrs:{"label":"Individual","value":"0"}}),_c('v-radio',{attrs:{"label":"Company","value":"1"}})],1)],1)]}},{key:"item.count",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[(item.hasOwnProperty('development_unit_details_count'))?_c('p',{domProps:{"innerHTML":_vm._s(item.development_unit_details_count)}}):(item.hasOwnProperty('project_unit_details_count'))?_c('p',{domProps:{"innerHTML":_vm._s(item.project_unit_details_count)}}):(item.hasOwnProperty('nat_contact_details_count'))?_c('p',{domProps:{"innerHTML":_vm._s(item.nat_contact_details_count)}}):_c('p',[_vm._v("0")])])]}}])})],1)],1)],1),(_vm.searchFormData.type === '5')?_c('v-row',[_c('div',{staticClass:"col-lg-4 col-sm-4"},[_c('div',{staticClass:"bg-light-success text-center pt-3 pb-3"},[_c('p',{staticClass:"font-weight-bold font-size-h3 mb-3"},[_vm._v(" Total Units ")]),_c('p',{staticClass:"font-weight-bold font-size-h6 m-0"},[_vm._v(" "+_vm._s(_vm.totalUnits)+" ")])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }